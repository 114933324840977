export class Lineup {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "Lineup";
    this.scene = "Lineup";
    this.data = [];
    this.page = 1;
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];
    if (options.new) {
      this.page = 1;
      delete options.new;
    }
    this.options = options;

    this.data.push({
      name: "Competition",
      value: { text: options.game?.competition?.name || "" },
    });

    this.data.push({
      name: "Competition Badge",
      value: { image: "${user}" + options.game?.competition?.badge || "" },
    });

    if (this.page === 2) {
      this.scene = "Lineup Subs";
      this.data.push({
        name: "Title",
        value: {
          text: "SUBSTITUTES",
        },
      });
    } else {
      this.scene = "Lineup";
      this.data.push({
        name: "Title",
        value: {
          text: "STARTING",
        },
      });
    }
    this.data.push({
      name: "Home Team Badge",
      value: {
        image: "${user}" + options.game?.home_team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Home Team Secondary Badge",
      value: {
        image: "${user}" + options.game?.home_team?.variables?.alt_badge,
      },
    });

    this.data.push({
      name: "Away Team Badge",
      value: {
        image: "${user}" + options.game?.away_team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Away Team Secondary Badge",
      value: {
        image: "${user}" + options.game?.away_team?.variables?.alt_badge,
      },
    });

    new Array(30).fill(1).forEach((_, index) => {
      let row = index + 1;

      let player = options.squads?.find(
        (s) => s.order === row + (this.page === 2 ? 11 : 0)
      );

      this.data.push({
        name: "First Name " + row,
        value: {
          text: player?.squad?.player?.first_name || "",
        },
      });
      this.data.push({
        name: "Last Name " + row,
        value: {
          text: player?.squad?.player?.last_name || "",
        },
      });
      this.data.push({
        name: "First Name Uppercase " + row,
        value: {
          text: player?.squad?.player?.first_name_upper || "",
        },
      });
      this.data.push({
        name: "Last Name Uppercase " + row,
        value: {
          text: player?.squad?.player?.last_name_upper || "",
        },
      });
      this.data.push({
        name: "Full Name " + row,
        value: {
          text:
            (player?.squad?.player?.first_name_upper || "") +
            " " +
            (player?.squad?.player?.last_name_upper || ""),
        },
      });

      this.data.push({
        name: "Captain " + row,
        value: {
          visible: player?.captain || false,
        },
      });
      this.data.push({
        name: "Position " + row,
        value: {
          text: player?.squad?.position || "",
        },
      });
      this.data.push({
        name: "Shirt Number " + row,
        value: {
          text: player?.squad?.shirt_number || "",
        },
      });
    });

    this.data.push({
      name: "Team Name",
      value: {
        text: options.team?.name,
      },
    });

    this.data.push({
      name: "Team Badge",
      value: {
        image: "${user}" + options.team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Team Secondary Badge",
      value: {
        image: "${user}" + options.team?.variables?.alt_badge || "",
      },
    });

    this.data.push({
      name: "Team Video Badge",
      value: {
        video: "${user}" + options.team?.variables?.video_badge,
      },
    });

    if (options?.game?.home_team?._id === options.team?._id) {
      this.data.push({
        name: "Team Kit BG Colour",
        value: {
          colour:
            options.team?.variables?.[
              options.game?.home_team_kit + "_kit_bg"
            ] || "",
        },
      });
      this.data.push({
        name: "Team Kit Text Colour",
        value: {
          colour:
            options.team?.variables?.[
              options.game?.home_team_kit + "_kit_text"
            ] || "",
        },
      });
    } else {
      this.data.push({
        name: "Team Kit BG Colour",
        value: {
          colour:
            options.team?.variables?.[
              options.game?.away_team_kit + "_kit_bg"
            ] || "",
        },
      });
      this.data.push({
        name: "Team Kit Text Colour",
        value: {
          colour:
            options.team?.variables?.[
              options.game?.away_team_kit + "_kit_text"
            ] || "",
        },
      });
    }

    let manager = options.manager;

    this.data.push({
      name: "Manager First Name Uppercase",
      value: {
        text: manager?.player?.first_name_upper || "",
      },
    });

    this.data.push({
      name: "Manager Last Name Uppercase",
      value: {
        text: manager?.player?.last_name_upper || "",
      },
    });

    this.data.push({
      name: "Manager Full Name",
      value: {
        text:
          (manager?.player?.first_name_upper || "") +
          " " +
          (manager?.player?.last_name_upper || ""),
      },
    });

    this.data.push({
      name: "Manager Title",
      value: {
        text: manager?.player?.title || "",
      },
    });

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;
    this.onAirScene = this.scene;
    this.sendAnimate(this.scene, this.data);
    if (this.page === 1) {
      this.page = 2;
      this.preview({ options: this.options });
    } else {
      this.page = 1;
    }
  }
  animateOff({ options }) {
    this.onAir = false;
    this.page = 1;
    this.sendAnimateOff(this.onAirScene, []);
  }
}
