import moment from "moment";

export class Fixtures {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "Fixtures";
    this.scene = "Fixtures";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;

    this.data = [];

    this.data.push({
      name: "Competition",
      value: { text: options.game?.competition?.name || "" },
    });

    this.data.push({
      name: "Competition Badge",
      value: { image: "${user}" + options.game?.competition?.badge || "" },
    });

    new Array(4)?.fill(1)?.map((_, index) => {
      let game = options?.fixtures[index];
      let row = index + 1;

      let status =
        game?.live_data?.periods?.filter((p) => p.kick_off !== null)?.length > 0
          ? "started"
          : "notstarted";

      this.data.push({
        name: "Game " + row + " Competition",
        value: { text: game?.competition?.name || "" },
      });

      this.data.push({
        name: "Game " + row + " Competition Badge",
        value: { image: "${user}" + game?.competition?.badge || "" },
      });
      this.data.push({
        name: "Game " + row + " Home Team Short Name",
        value: { text: game?.home_team?.short_name || "" },
      });
      let home_team = game?.home_team?.name || "";

      if (status === "notstarted" && !game?.home_team && game) {
        if (
          game?.description === "Semi-Finals" ||
          game?.description === "Final"
        ) {
          // if (row === 1) {
          //   home_team = "AMSTERDAM/PLUNGE";
          // }
          // if (row === 2) {
          //   home_team = "PRINCETON/JEDDAH";
          // }
          if (row === 1) {
            home_team = "QF 1 winner";
          }
          if (row === 2) {
            home_team = "QF 3 winner";
          }
          if (row === 3) {
            home_team = "SF 1 winner";
          }
          // home_team = game?.description;
        } else {
          // home_team = game?.description;
        }
      }

      this.data.push({
        name: "Game " + row + " Home Team Name",
        value: { text: home_team },
      });

      this.data.push({
        name: "Game " + row + " Home Team Badge",
        value: { image: game?.home_team?.variables?.badge || "" },
      });

      this.data.push({
        name: "Game " + row + " Home Team Score",
        value: { text: game?.home_score || "0" },
      });
      let away_team = game?.away_team?.name || "";
      if (status === "notstarted" && !game?.away_team && game) {
        if (
          game?.description === "Semi-Finals" ||
          game?.description === "Final"
        ) {
          // if (row === 1) {
          //   away_team = "WARSAW/KRUPANJ";
          // } else if (row === 2) {
          //   away_team = "HANGZHOU/KANDAVA";
          // }
          if (row === 1) {
            away_team = "QF 2 winner";
          }
          if (row === 2) {
            away_team = "QF 4 winner";
          }
          if (row === 3) {
            away_team = "SF 2 winner";
          }
          // away_team = game?.description;
        } else {
          // away_team = game?.description;
        }
      }
      this.data.push({
        name: "Game " + row + " Away Team Name",
        value: { text: away_team },
      });

      this.data.push({
        name: "Game " + row + " Away Team Short Name",
        value: { text: game?.away_team?.short_name || "" },
      });

      this.data.push({
        name: "Game " + row + " Away Team Badge",
        value: { image: game?.away_team?.variables?.badge || "" },
      });
      this.data.push({
        name: "Game " + row + " Away Team Score",
        value: { text: game?.away_score || "0" },
      });
      this.data.push({
        name: "Game " + row + " Date",
        value: { text: game?.date || "" },
      });

      this.data.push({
        name: "Game " + row + " Time",
        value: { text: game?.time || "" },
      });

      this.data.push({
        name: "Game " + row + " Status",
        value: {
          text: status,
        },
      });
    });

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
