import {
  ApolloClient,
  createHttpLink,
  getApolloContext,
  gql,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import React, { useState, useEffect, useContext, useCallback } from "react";
import useLocalStorage from "../Hooks/useLocalStorage";

const GET_ENGINES = gql`
  query GetEngines {
    enginesForUser {
      name
      _id
      key
    }
  }
`;

const ControlContext = React.createContext([{}, () => {}]);
window.scenesOnAir = [];

window.control_ws = null;

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    },
  };
});

const httpLink = createHttpLink({
  uri:
    (window.ENV?.REACT_APP_PROJECT_SERVER ||
      process.env.REACT_APP_PROJECT_SERVER) + "/graphql",
});

const project_client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

const ControlProvider = (props) => {
  const { client } = useContext(getApolloContext());
  const [ws, setWS] = useState(null);
  const [wsStatus, setWSStatus] = useState(-1);
  const [scenesOnAir, setScenesOnAir] = useState([]);
  const [usersOnAir, setUserOnAir] = useState([]);
  const [engines, setEngines] = useState([]);
  const [selectedEngine, setSelectedEngine] = useLocalStorage(
    "football-selected-engine",
    []
  );
  const [themeName, setThemeName] = useState("football");

  useEffect(() => {
    project_client
      .query({
        query: GET_ENGINES,
      })
      .then((response) => {
        window.engines = response.data.enginesForUser.map((e) => ({
          ...e,
        }));
        setEngines(
          response.data.enginesForUser.map((e) => ({
            ...e,
          }))
        );
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    window.selectedEngine = selectedEngine;
  }, [selectedEngine]);

  const sendData = (data) => {
    try {
      if (window.control_ws) {
        data.user = {
          id: window.id,
        };

        data.group = localStorage.getItem("user-id");
        data.app = "basketball";

        data.engine =
          window.engines?.find((e) => e.key === window.selectedEngine)?.key ||
          window.engines?.[0]?.key;
        window.control_ws.send(JSON.stringify({ ...data, from_control: true }));
      }
    } catch (err) {}
  };

  const animateOff = useCallback(
    (name, data) => {
      animateOffLocal(name, data);
    },
    [scenesOnAir, setScenesOnAir, ws, sendData]
  );

  const animate = useCallback(
    (name, data) => {
      animateLocal(name, data);
    },
    [scenesOnAir, setScenesOnAir, ws, sendData]
  );

  useEffect(() => {
    connect();
  }, []);

  function connect() {
    console.log("Football Control connecting to server");

    window.control_ws = new WebSocket(window.command_server);
    setWS(window.control_ws);
  }

  useEffect(() => {
    if (window.control_ws) {
      let timeout;
      window.control_ws.onopen = () => {
        setWSStatus(1);
        console.log("Football Control on open");
        clearInterval(window.keep_alive);
        window.keep_alive = setInterval(() => {
          window.control_ws.send(
            JSON.stringify({
              keep_alive: Date.now(),
              group: window.id,
            })
          );
        }, 20000);
        sendData({
          group: window.id,
        });
      };
      window.control_ws.onmessage = (data) => {
        console.log("Football Control on message");
        try {
          let obj = JSON.parse(data.data);
          if (obj) {
            if (obj.type === "users") {
              setUserOnAir(obj.users);
            }
          }
        } catch (err) {
          console.error(err);
        }
      };
      window.control_ws.onerror = (err) => {
        console.log("Football Control on message");
        ws.close();
      };
      window.control_ws.onclose = (data) => {
        setWSStatus(0);
        console.log("Football Control on close");
        clearTimeout(timeout);
        clearInterval(window.keep_alive);
        timeout = setTimeout(() => {
          connect();
        }, 1000);
      };
    }
  }, [ws]);

  function updateGlobals(theme) {
    sendData({
      action: "globals",
      group: window.id,
      data: {
        globals: theme.data,
      },
    });
  }

  function preview(name, frame, data, timline) {
    sendData({
      action: "preview",
      group: window.id,
      engine: window.engines?.[0]?.key,
      data: {
        scene: name,
        frame: frame,
        timeline: timline || "IN",
        data: data,
      },
    });
  }

  function clearPreview(name) {
    sendData({
      action: "CLEAR_PREVIEW",
      group: window.id,
      engine: window.engines?.[0]?.key,
      data: {
        scene: name,
      },
    });
  }

  function clear(group) {
    setScenesOnAir([]);
    sendData({
      action: "CLEAR",
      group: "",
      engine: window.engines?.[0]?.key,
    });

    // ws.send(
    //   JSON.stringify({
    //     action: "CLEAR",
    //     group: group.name,
    //   })
    // );
  }

  function animateLocal(name, data, pageNumber) {
    clearPreview(name);
    sendData({
      action: "animate",
      group: window.id,
      engine: window.engines?.[0]?.key,
      data: {
        scene: name,
        timeline: "IN",
        data: data,
      },
    });
  }

  function update(name, data) {
    sendData({
      action: "update",
      group: window.id,
      engine: window.engines?.[0]?.key,
      data: {
        scene: name,
        data: data,
      },
    });
  }

  function triggerAnimation(name, data, animation) {
    sendData({
      action: "animate",
      group: window.id,
      engine: window.engines?.[0]?.key,
      data: {
        scene: name,
        timeline: animation,
        data: updateData(data),
      },
    });
  }

  function animateOffLocal(name, data) {
    sendData({
      action: "animate",
      group: window.id,
      engine: window.engines?.[0]?.key,
      data: {
        scene: name,
        timeline: "OUT",
        data: [],
      },
    });
  }

  function updateData(data) {
    if (data && data.length > 0) {
      return data.map((item) => {
        if (item.type === "TEXT") {
          return {
            name: item.name,
            value: {
              visible: item.visible,
              text: item.text,
              colour: item.colour,
              fontSize: item.fontSize || item.style._fontSize,
              position: {
                x: item.x,
                y: item.y,
              },
            },
          };
        } else if (item.type === "IMAGE") {
          if (item.name === "FG_IMAGE") {
            return {
              name: item.name,
              value: {
                visible: item.visible,
                image: item.image || item.src,
                width: item.width,
                height: item.height,
              },
            };
          } else {
            return {
              name: item.name,
              value: {
                visible: item.visible,
                image: item.image || item.src,
                position: {
                  x: item.x,
                  y: item.y,
                },
                width: item.width,
                height: item.height,
              },
            };
          }
        } else if (item.type === "GROUP") {
          return {
            name: item.name,
            value: {
              visible: item.visible,
              position: {
                x: item.x,
                y: item.y,
              },
            },
          };
        }
      });
    }
    return [];
  }

  function connectEngine(engine) {
    sendData({
      type: engine.status ? "connect-engine" : "disconnect-engine",
      engine: engine.name,
    });
  }

  return (
    <ControlContext.Provider
      value={{
        sendData,
        preview,
        animate,
        animateOff,
        scenesOnAir,
        clearPreview,
        setThemeName,
        wsStatus,
        clear,
        triggerAnimation,
        update,
        connectEngine,
        usersOnAir,
        engines,
        selectedEngine,
        setSelectedEngine,
      }}
    >
      {props.children}
    </ControlContext.Provider>
  );
};

export { ControlContext, ControlProvider };
