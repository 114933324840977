import React, { useContext } from "react";
import styled from "styled-components";
import { UIContext, VIEW_CONSTS } from "../../contexts/UIContext";
import { APIContext } from "../../contexts/APIContext";
import useLocalStorage from "../../Hooks/useLocalStorage";
import { Button } from "../../Theme/Hyper";

const TeamNamePanel = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1em;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: #181d25;
  height: 7vh;
`;
const Team = styled.div`
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  display: flex;
  width: 40%;
  align-items: center;
  height: 54px;
`;
const Middle = styled.div`
  display: flex;
  width: 50%;
  justify-content: space-evenly;
  align-items: center;
  margin-top: -10px;
`;
const TeamName = styled.div`
  padding-left: ${(props) => (props.home ? "0.5em" : "")};
  margin-left: ${(props) => (props.home ? "auto" : "")};
  display: flex;
  font-size: 1.5em;
  cursor: pointer;
`;
const Score = styled.div`
  display: flex;
  font-size: 4em;
  width: 100%;
  justify-content: center;
  cursor: pointer;
  border-radius: 2px;
  :hover {
    background-color: #db0a41;
  }
`;

const Red = styled.div`
  display: flex;
  width: 40px;
  height: 20px;
  background-color: ${({ selected }) => (selected ? "#d93232" : "")};
  border: 1px solid #d93232;
  margin: 5px;
  border-radius: 2px;
  cursor: pointer;
  :hover {
    background-color: #d93232;
  }
`;

const ScoreButton = styled(Button)`
  margin: 10px;
  height: 40px;
  min-width: 60px;
  justify-content: center;
`;
export default function TeamNames({
  setGoalScored,
  game,
  setSelectedPlayer,
  group,
}) {
  const { setView, selectedTeam, setGoal } = useContext(UIContext);
  const { updateGame } = useContext(APIContext);
  const [homeReds, setHomeReds] = useLocalStorage("home_reds");
  const [awayReds, setAwayReds] = useLocalStorage("away_reds");

  let homeBGColour =
    game.home_team?.variables?.[game?.home_team_kit + "_kit_bg"] || "";
  let awayBGColour =
    game.away_team?.variables?.[game?.away_team_kit + "_kit_bg"] || "";

  return (
    <TeamNamePanel>
      <Team
        style={{
          borderBottom:
            "3px solid " + (homeBGColour ? homeBGColour : "rgba(0,0,0,0)"),
          opacity:
            !selectedTeam || selectedTeam._id === game.home_team._id
              ? "1"
              : ".2",
        }}
      >
        <TeamName home>{game.home_team?.name}</TeamName>
      </Team>
      <Middle>
        <ScoreButton
          onContextMenu={(e) => {
            e.preventDefault();
          }}
          onMouseDown={(e) => {
            switch (e.button) {
              case 0:
                updateGame({
                  id: game._id,
                  home_score: game?.home_score + 1,
                });
                break;
              case 2:
                if (game?.home_score - 1 >= 0) {
                  updateGame({
                    id: game._id,
                    home_score: game?.home_score - 1,
                  });
                }
                break;
              default:
                break;
            }
          }}
        >
          1pt
        </ScoreButton>
        <ScoreButton
          onContextMenu={(e) => {
            e.preventDefault();
          }}
          onMouseDown={(e) => {
            switch (e.button) {
              case 0:
                updateGame({
                  id: game._id,
                  home_score: game?.home_score + 2,
                });
                break;
              case 2:
                if (game?.home_score - 2 >= 0) {
                  updateGame({
                    id: game._id,
                    home_score: game?.home_score - 2,
                  });
                }
                break;
              default:
                break;
            }
          }}
        >
          2pts
        </ScoreButton>
        <Score>{game?.home_score || 0}</Score>-
        <Score right>{game?.away_score || 0}</Score>
        <ScoreButton
          onContextMenu={(e) => {
            e.preventDefault();
          }}
          onMouseDown={(e) => {
            switch (e.button) {
              case 0:
                updateGame({
                  id: game._id,
                  away_score: game?.away_score + 1,
                });

                break;
              case 2:
                if (game?.away_score - 1 >= 0) {
                  updateGame({
                    id: game._id,
                    away_score: game?.away_score - 1,
                  });
                }
                break;
              default:
                break;
            }
          }}
        >
          1pt
        </ScoreButton>
        <ScoreButton
          onContextMenu={(e) => {
            e.preventDefault();
          }}
          onMouseDown={(e) => {
            switch (e.button) {
              case 0:
                updateGame({
                  id: game._id,
                  away_score: game?.away_score + 2,
                });
                break;
              case 2:
                if (game?.away_score - 2 >= 0) {
                  updateGame({
                    id: game._id,
                    away_score: game?.away_score - 2,
                  });
                }
                break;
              default:
                break;
            }
          }}
        >
          2pts
        </ScoreButton>
      </Middle>
      <Team
        style={{
          borderBottom:
            "3px solid " + (awayBGColour ? awayBGColour : "rgba(0,0,0,0)"),
          opacity:
            !selectedTeam || selectedTeam._id === game.away_team._id
              ? "1"
              : ".2",
        }}
      >
        <TeamName>{game.away_team?.name}</TeamName>
      </Team>
    </TeamNamePanel>
  );
}
