import React from "react";
import styled from "styled-components";
import moment from "moment";
const Button = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #181d25;
  height: 5%;
  width: 90%;
  min-height: 50px;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 1em;
  justify-content: center;
  align-items: center;
  padding: 0.2em;
  cursor: pointer;
  :hover {
    background-color: #db0a41;
  }
`;
const Title = styled.div`
  display: flex;
  justify-content: cetner;
`;
const Caption = styled.div`
  display: flex;
  justify-content: center;
  font-size: 0.8em;
  text-align: center;
`;
export default function Player({
  player,
  previewGraphic,
  game,
  sky,
  countries,
  period,
}) {
  return (
    <>
      <Button
        onClick={() => {
          previewGraphic("Name", {
            player: player,
            subtext: player?.team?.name,
            team: player?.team,
            sky: sky,
            countries: countries,
            game,
          });
        }}
      >
        Name
      </Button>
    </>
  );
}
