import React, { useContext } from "react";
import { FaHome } from "react-icons/fa";
import { FiSettings, FiClock } from "react-icons/fi";
import styled from "styled-components";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { IoIosStats } from "react-icons/io";
import { GiSoccerField, GiSoccerBall } from "react-icons/gi";
import { GoCalendar, GoFile } from "react-icons/go";
import { UIContext, VIEW_CONSTS } from "../../contexts/UIContext";

const Main = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 0.5em;
  font-size: 1.5em;
  flex-direction: column;
  height: 100vh;
`;

const BottomIcons = styled.div`
  display: flex;
  margin-top: auto;
  margin-bottom: 10px;
  align-items: center;
  flex-direction: column;
`;

const SettingsIcon = styled(FiSettings)`
  cursor: pointer;
`;

const LogIcon = styled(GoFile)`
  cursor: pointer;
`;
const Icon = styled.div`
  cursor: pointer;
  background-color: ${(props) => (props.selected === true ? "#db0a41" : "")};
  width: 100%;
  display: flex;
  justify-content: center;
  height: ${(props) => (props.home ? "92px" : "50px")};
  align-items: center;
  border-bottom: ${(props) => (props.home ? "1px solid #3d485d" : "")};
  box-shadow: ${(props) =>
    props.home ? "1px 5px 4px 1px rgb(0 0 0 / 25%)" : ""};
`;
export default function LeftSideBar({ view }) {
  const history = useHistory();
  const location = useLocation();
  let { gameID } = useParams();

  return (
    <Main
      style={
        (view === VIEW_CONSTS.matchSelect && {
          height: "calc(100vh - 50px)",
        }) ||
        {}
      }
    >
      {view !== VIEW_CONSTS.matchSelect && (
        <Icon
          home
          onClick={() => {
            history.push("/" + gameID);
          }}
        >
          <FaHome />
        </Icon>
      )}
      {view !== VIEW_CONSTS.matchSelect && (
        <Icon
          selected={view === VIEW_CONSTS.clockEdit}
          onClick={() => {
            history.push("/" + gameID + "/clock");
          }}
        >
          <FiClock />
        </Icon>
      )}
      {(view === VIEW_CONSTS.formation || view === VIEW_CONSTS.stats) && (
        <Icon
          selected={view === VIEW_CONSTS.formation}
          // onClick={() => setView(VIEW_CONSTS.formation)}
        >
          <GiSoccerField />
        </Icon>
      )}
      {(view === VIEW_CONSTS.formation || view === VIEW_CONSTS.stats) && (
        <Icon
          selected={view === VIEW_CONSTS.stats}
          //onClick={() => setView(VIEW_CONSTS.stats)}
        >
          <IoIosStats />
        </Icon>
      )}
      {gameID && (
        <Icon
          selected={view === VIEW_CONSTS.goals}
          onClick={() => {
            history.push("/" + gameID + "/goals");
          }}
        >
          <GiSoccerBall />
        </Icon>
      )}
      <BottomIcons
        style={{ marginBottom: window.hideHeader ? "50px" : "20px" }}
      >
        <Icon
          onClick={() =>
            history.push({
              pathname: "/",
            })
          }
        >
          <GoCalendar />
        </Icon>
      </BottomIcons>

      {/* <Icon
        selected={view === VIEW_CONSTS.all_graphics}
        onClick={() => setView(VIEW_CONSTS.all_graphics)}
      >
        <MdClearAll></MdClearAll>
      </Icon>
      <BottomIcons>
        <Icon
          onClick={() => {
            setSelectedGameID(null);
            history.push({
              pathname: mode === MODE_CONSTS.live ? "/" : "/MatchChoice",
            });
          }}
        >
          <GoCalendar />
        </Icon>
        <Icon>
          <LogIcon onClick={() => history.push({ pathname: "/Logs" })} />
        </Icon>
        <Icon>
          <SettingsIcon onClick={() => history.push({ pathname: "/Config" })} />
        </Icon> 
      </BottomIcons>*/}
    </Main>
  );
}
