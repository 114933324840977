import React, { useState, useEffect } from "react";

const ClockContext = React.createContext([{}, () => {}]);

const ClockProvider = (props) => {
  const [ws, setWS] = useState(null);
  const [wsStatus, setWSStatus] = useState(-1);

  function connect({ event_id }) {
    console.log("Basketball Control connecting to fiba 3x3 clock");
    window.clock_event_id = event_id;
    window.clock_ws = new WebSocket(window.clock_server);
    setWS(window.clock_ws);
  }

  useEffect(() => {
    if (window.clock_ws) {
      let timeout;
      window.clock_ws.onopen = () => {
        setWSStatus(1);
        console.log("Football Control on open");

        window.clock_ws.send(
          JSON.stringify({
            apiName: "TvFeedApiV4",
            apiCommand: "subscribe",
            apiKey: window.clock_api_key,
            requestId: "RANDOM_UUID",
            eventId: window.clock_event_id,
            fastUpdates: true,
          })
        );
      };
      window.clock_ws.onmessage = (data) => {
        data = JSON.parse(data.data);

        if (
          data?.data?.[[Object.keys(data?.data)]?.[0]]?.timeRemainingFormatted
        ) {
          try {
            document.getElementById("CLOCK").innerText =
              data?.data?.[
                [Object.keys(data?.data)]?.[0]
              ]?.timeRemainingFormatted;
          } catch (err) {}
        }
      };
      window.clock_ws.onerror = (err) => {
        console.log("Football Control on message");
        ws.close();
      };
      window.clock_ws.onclose = (data) => {
        setWSStatus(0);
        console.log("Football Control on close");
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          connect();
        }, 1000);
      };
    }
  }, [ws]);

  return (
    <ClockContext.Provider
      value={{
        wsStatus,
        connect,
      }}
    >
      {props.children}
    </ClockContext.Provider>
  );
};

export { ClockContext, ClockProvider };
